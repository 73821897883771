/* Now loading google fonts on the index.html file */
/* @import url('https://fonts.googleapis.com/css?family=Montserrat%3Aregular%2C700%2C500%2C900&display=swap'); */

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  width: 100%;
  font-family: Montserrat;
  font-weight: 400;
  font-style: normal;
}

p {
  font-size: 0.99rem;
}

/* COLORS */
.bg-blue-dark {
  background-color: rgb(11, 20, 31);
}
.bg-gray-light {
  background-color: rgb(246, 247, 247);
}
.bg-light-blue {
  background-color: rgb(228, 232, 252);
}
.bg-blue-1 {
  background-color: rgb(0, 33, 81);
}
.bg-blue-2 {
  background-color: rgb(15, 72, 122);
}
.bg-blue-3 {
  background-color: rgb(35, 79, 158);
}
.bg-blue-4 {
  background-color: rgb(38, 106, 166);
}
.bg-footer {
  background-color: rgb(40, 51, 65);
}

/* BUTTONS */
.btn-main {
  background: #c5cdf9;
  color: #001894;
  font-size: 0.9907rem;
  font-family: Montserrat;
  font-weight: 500;
  font-style: normal;
  height: 3rem;
  line-height: 2rem;
  width: 12.8125rem;
  border-radius: 41px;
}
.btn-main:hover {
  background: #1565c0;
  color: #ffffff;
}

.btn-form {
  background: #001894;
  color: #ffffff;
  font-size: 0.8669rem;
  font-family: Montserrat;
  font-weight: 400;
  font-style: normal;
  height: 2.5rem;
  width: 10.9375rem;
  border-radius: 41px;
}
.btn-form:hover {
  background: #1565c0;
  color: #ffffff;
}

/* FORMS */
.form-select {
  background-image: url("data:image/svg+xml,%3Csvg height='1em' fill='currentcolor' viewBox='0 0 320 512' aria-hidden='true' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'%3E%3C/path%3E%3C/svg%3E");
  background-position: right 0.3rem center;
  background-size: 15px 14px;
}
#reg-form .form-control,
#reg-form .rw-widget-input {
  border: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
  font-size: 0.86rem;
  line-height: 1.375rem;
  box-shadow: none;
  min-height: auto;
  padding: 0.375rem 0rem;
  padding-bottom: 0;
}
#reg-form .rw-dropdown-list-input {
  padding-left: 0;
}

#reg-form .form-control::placeholder,
#reg-form .form-control::-webkit-input-placeholder,
#reg-form .form-control::-ms-input-placeholder {
  color: #000;
  opacity: 1; /* Firefox */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.z-10 {
  z-index: -10;
}

.hero-bg {
  background: #0c3451 !important;
  /* background: url('./assets/skin-web-test-vocacional-uss.webp') repeat center
    center / cover; */
}
.img-areas-interes-1 > div {
  background: url('./assets/areas-interes-1.png') -3.1246rem -0.0625rem /
    14.875rem 10.9375rem;
  background-position: top center;
  background-repeat: no-repeat;
}
.img-areas-interes-2 > div {
  background: url('./assets/areas-interes-2.png') -2.9998rem -0.0625rem /
    14.875rem 10.9375rem;
  background-position: top center;
  background-repeat: no-repeat;
}
.img-areas-interes-3 > div {
  background: url('./assets/areas-interes-3.png') -2.9998rem -0.0625rem /
    14.875rem 10.9375rem;
  background-position: top center;
  background-repeat: no-repeat;
}
.img-areas-interes-4 > div {
  background: url('./assets/areas-interes-4.png') -3.1875rem -0.0625rem /
    14.875rem 10.9375rem;
  background-position: top center;
  background-repeat: no-repeat;
}
.img-areas-interes-5 > div {
  background: url('./assets/areas-interes-4.png') -3.1875rem -0.0625rem /
    14.875rem 10.9375rem;
  background-position: top center;
  background-repeat: no-repeat;
}
.bg-estudiante {
  height: 100%;
  width: 100%;
  background: url('./assets/bg-estudiante.webp');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-estudiante-small {
  height: 21rem;
  width: 100%;
  background: url('./assets/bg-estudiante-small.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.footer-logo-1 {
  height: 5.5rem;
  width: 13.75rem;
  background: url('./assets/logos-footer.png') -26.7495rem -4.75rem / 62.25rem
    13.75rem;
}
.footer-logo-2 {
  height: 5.875rem;
  width: 7.5rem;
  background: url('./assets/logos-footer.png') -51.7495rem -4.562rem / 62.25rem
    13.75rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.whatsapp-btn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;
}

.whatsapp-btn img {
  width: 70px; /* Cambia este valor para ajustar el ancho del ícono */
  height: auto; /* Mantener la proporción original */
}

/* MEDIA QUERIES */

/* SMALL DEVICES (BOOTSTRAP) */
@media (max-width: 575.98px) {
  #slogan {
    transform: scale(0.75);
  }
}

/* MEDIUM DEVICES (BOOTSTRAP) */
@media (min-width: 768px) {
  .hero-bg {
    background: url('./assets/test-vocacional-uss-2025.webp') repeat center
      center / cover !important;
  }
}
